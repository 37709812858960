import React from "react";
import CookieSetting from "../../components/cookie-setting";

function TestCookieSetting() {
    return (
        <CookieSetting/>
    )

}
export default  TestCookieSetting;
